@charset "UTF-8";

/* 記事スタイル */

/*点線背景*/

.txt-border {
  background-image: url(../img/dott.gif);
  background-repeat: repeat;
  background-position: left top;
  font-size: 13px;
  line-height: 30px;
  margin: 10px;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  p {
    font-size: 13px;
    line-height: 30px;
  }
}

.txt-line {
  border: 2px solid #CCCCCC;
}

.txt-yellowback {
  background-color: #FFF9DF;
  border: 2px solid #CC0000;
}

/*灰色背景*/

.txt-grayback {
  background-color: #e6e6e6;
  border: 3px solid #f6f6f6;
  .body {
    background-color: #fbfbfb;
  }
}

/*カラー背景*/

.txt-colorback {
  background-color: #ffe591;
  border: 3px solid #fff8e1;
  .body {
    background-color: #fffcf2;
  }
}

/*灰色枠*/

.txt-frame {
  background-image: url(../img/frame_l.gif);
  background-repeat: repeat-y;
  background-position: left top;
  .top {
    background-image: url(../img/frame_t.gif);
    background-repeat: no-repeat;
    background-position: left top;
    height: 7px;
    overflow: hidden;
  }
  .top_inner {
    background-image: url(../img/frame_tr.gif);
    background-repeat: no-repeat;
    background-position: right top;
    height: 7px;
  }
  .body {
    background-image: url(../img/frame_r.gif);
    background-repeat: repeat-y;
    background-position: right top;
    padding: 20px 25px;
  }
  .bottom {
    background-image: url(../img/frame_b.gif);
    background-repeat: no-repeat;
    background-position: left top;
    height: 7px;
    overflow: hidden;
    span {
      background-image: url(../img/frame_br.gif);
      background-repeat: no-repeat;
      background-position: right top;
      display: block;
      height: 7px;
    }
  }
}

/*カラー枠*/

.txt-colorframe {
  background-image: url(../img/colorframe_l.gif);
  background-repeat: repeat-y;
  background-position: left top;
  .top {
    background-image: url(../img/colorframe_t.gif);
    background-repeat: no-repeat;
    background-position: left top;
    height: 7px;
    overflow: hidden;
  }
  .top_inner {
    background-image: url(../img/colorframe_tr.gif);
    background-repeat: no-repeat;
    background-position: right top;
    height: 7px;
  }
  .body {
    background-image: url(../img/colorframe_r.gif);
    background-repeat: repeat-y;
    background-position: right top;
    padding: 20px 25px;
  }
  .bottom {
    background-image: url(../img/colorframe_b.gif);
    background-repeat: no-repeat;
    background-position: left top;
    height: 7px;
    overflow: hidden;
    span {
      background-image: url(../img/colorframe_br.gif);
      background-repeat: no-repeat;
      background-position: right top;
      display: block;
      height: 7px;
    }
  }
}

/*角丸枠*/

.txt-rndbox {
  background-image: url(../img/round_l.gif);
  background-repeat: repeat-y;
  background-position: left top;
  border: none !important;
  box-shadow: none !important;
  .top {
    background-image: url(../img/round_t.gif);
    background-repeat: round;
    background-position: left top;
    height: 15px;
    overflow: hidden;
  }
  .top_inner {
    background-image: url(../img/round_tr.gif);
    background-repeat: no-repeat;
    background-position: right top;
    height: 15px;
  }
  .body {
    background-image: url(../img/round_r.gif);
    background-repeat: repeat-y;
    background-position: right top;
    padding: 10px 25px;
  }
  .bottom {
    background-image: url(../img/round_b.gif);
    background-repeat: round;
    background-position: left top;
    height: 15px;
    overflow: hidden;
    span {
      background-image: url(../img/round_br.gif);
      background-repeat: no-repeat;
      background-position: right top;
      display: block;
      height: 15px;
    }
  }
}

/* 飾り枠用見出しリセット */

#main {
  .txt-decbox1 {
    h2, h3, h2 span, h3 span {
      margin: 0 !important;
      background-image: none;
      padding: 0;
      border: none;
      background-color: transparent;
      text-align: center;
      font-size: 20px;
      line-height: 1.3;
    }
  }
  .txt-decbox2 {
    h2, h3, h2 span, h3 span {
      margin: 0 !important;
      background-image: none;
      padding: 0;
      border: none;
      background-color: transparent;
      text-align: center;
      font-size: 20px;
      line-height: 1.3;
    }
  }
  .txt-decbox1 {
    h2:before, h3:before {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox2 {
    h2:before, h3:before {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox1 {
    h2:after, h3:after {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox2 {
    h2:after, h3:after {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox1 {
    h2 span:before, h3 span:before {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox2 {
    h2 span:before, h3 span:before {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox1 {
    h2 span:after, h3 span:after {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
  .txt-decbox2 {
    h2 span:after, h3 span:after {
      background: none;
      border: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      content: "";
      position: static !important;
      z-index: -1;
      line-height: 0;
      display: none;
      background-color: #fffbee;
    }
  }
}

#text1.txt-decbox1, #text2.txt-decbox1, #text3.txt-decbox1, #text4.txt-decbox1, #text5.txt-decbox1, #text6.txt-decbox1, #text7.txt-decbox1, #text8.txt-decbox1, #text9.txt-decbox1, #text10.txt-decbox1 {
  background-color: #fffbee;
}

/*飾り枠1*/

.txt-decbox1 {
  background-color: #fffbee;
  background-image: url(../img/decbox_l.gif);
  background-repeat: repeat-y;
  background-position: left top;
  .top {
    background-image: url(../img/decbox_t.gif);
    background-repeat: no-repeat;
    background-position: left top;
    height: auto;
    padding: 0;
  }
  .top_inner {
    background-image: url(../img/decbox_r.gif);
    background-repeat: repeat-y;
    background-position: right top;
    .top_box {
      background-image: url(../img/decbox_tr.gif);
      background-repeat: no-repeat;
      background-position: right top;
      text-align: center;
      margin: 0;
      min-height: 36px;
    }
  }
}

#main .txt-decbox1 {
  h2, h3 {
    display: block;
    line-height: 120%;
    padding: 30px 60px 10px;
  }
}

.txt-decbox1 {
  .body {
    background-image: url(../img/decbox_r.gif);
    background-repeat: repeat-y;
    background-position: right top;
    padding: 10px 30px 0;
    width: auto;
  }
  .bottom {
    background-image: url(../img/decbox_b.gif);
    background-repeat: no-repeat;
    background-position: left top;
    height: 50px;
    padding: 0;
    span {
      background-image: url(../img/decbox_br.gif);
      background-repeat: no-repeat;
      background-position: right top;
      display: block;
      height: 50px;
      margin: 0;
    }
  }
}

/*飾り枠2*/

.txt-decbox2 {
  background-image: url(../img/decbox2_r.gif);
  background-position: right bottom;
  background-repeat: repeat-y;
  .top {
    background-image: url(../img/decbox2_tr.gif);
    background-repeat: no-repeat;
    background-position: right top;
    padding: 16px 16px 0 0;
    height: auto;
  }
  .top_inner {
    background-image: url(../img/decbox2_l.gif);
    background-position: left top;
    background-repeat: repeat-y;
    .top_box {
      background-image: url(../img/decbox2_t.gif);
      background-position: left top;
      background-repeat: no-repeat;
      padding-left: 16px;
      position: relative;
      top: -16px;
    }
    .top_box2 {
      background-image: url(../img/decbox2_t2.gif);
      background-position: center top;
      background-repeat: no-repeat;
      display: block;
      text-align: center;
      min-height: 30px;
    }
  }
}

#main .txt-decbox2 {
  h2 span, h3 span {
    padding: 50px 14px 0;
  }
}

.txt-decbox2 {
  .body {
    background-image: url(../img/decbox2_l.gif);
    background-position: left bottom;
    background-repeat: repeat-y;
    margin-bottom: -20px;
    margin-right: 16px;
    overflow: hidden;
    padding: 0 14px 0 30px;
    position: relative;
  }
  .bottom {
    background-image: url(../img/decbox2_br.gif);
    background-position: right top;
    background-repeat: no-repeat;
    height: 60px;
    span {
      background-image: url(../img/decbox2_b.gif);
      background-position: left top;
      background-repeat: no-repeat;
      display: block;
      height: 60px;
    }
  }
}

#main {
  .txt-grayback, .txt-colorback, .txt-line, .txt-frame, .txt-colorframe, .txt-rndbox {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .txt-grayback, .txt-colorback {
    padding: 1px;
  }
  .txt-grayback .body, .txt-colorback .body {
    padding: 20px;
  }
  .txt-frame, .txt-colorframe, .txt-rndbox, .txt-decbox1, .txt-decbox2 {
    padding: 0;
  }
  .txt-decbox1 .top {
    h2 span, h3 span {
      color: #f2ad36;
    }
  }
  .txt-decbox2 .top {
    h2 span, h3 span {
      color: #f8aa0d;
    }
  }
}

/* 記事スタイル - 色差分 */

.hd {
  color: #333;
  text-decoration: none;
}

/* 文字装飾 */

blockquote {
  font-size: 12px;
  color: #666666;
  border: 2px dotted #CCCCCC;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin: 15px;
  line-height: 130%;
}

/* 水平線 */

.border1, .border2, .border3, .borderdot, .borderdot2 {
  margin: 10px 0;
}

/* 角丸 */

.rbox3 {
  background-color: #FFF;
  border: 1px solid #d7d7d7;
}